import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadIndicatorService {
  private activeConnections$ = new BehaviorSubject<number>(0);
  private maintenanceCheckCount = 0;

  getActiveConnections() {
    return this.activeConnections$;
  }

  addConnection(): void {
    this.activeConnections$.next(this.activeConnections$.value + 1);
  }

  releaseConnection(): void {
    if (this.activeConnections$.value > 0) {
      this.activeConnections$.next(this.activeConnections$.value - 1);
    }
  }

  setMaintenanceCheckCount(count: number): void {
    this.maintenanceCheckCount = count;
  }

  getMaintenanceCheckCount(): number {
    return this.maintenanceCheckCount;
  }
}
