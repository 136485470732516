import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoadIndicatorInterceptor as Interceptor } from '@cuepid/core';
import { LoadIndicatorComponent } from '@cuepid/enterprise/shared/components/load-indicator/load-indicator.component';
import { LoadIndicatorService } from '../services/load-indicator.service';

@Injectable()
export class LoadIndicatorInterceptor extends Interceptor {
  constructor(
    private readonly dialog: MatDialog,
    private readonly loadIndicatorService: LoadIndicatorService
  ) {
    super();
  }

  protected getDialog(): MatDialogRef<any> {
    return this.dialog.open(LoadIndicatorComponent, {
      disableClose: true,
    });
  }

  protected addConnection(): void {
    const currentAPICount =
      this.loadIndicatorService.getActiveConnections().value;
    const maintenanceCheckAPICount =
      this.loadIndicatorService.getMaintenanceCheckCount();
    if (
      this.ref === null &&
      (currentAPICount === 0 || maintenanceCheckAPICount > 0)
    ) {
      this.ref = this.getDialog();
      if (maintenanceCheckAPICount > 0) {
        this.loadIndicatorService
          .getActiveConnections()
          .next(
            this.loadIndicatorService.getActiveConnections().value +
              maintenanceCheckAPICount
          );
        this.loadIndicatorService.setMaintenanceCheckCount(0);
      }
    }
    this.loadIndicatorService.addConnection();
  }

  protected releaseConnection(): void {
    this.loadIndicatorService.releaseConnection();
    const currentAPICount =
      this.loadIndicatorService.getActiveConnections().value;
    if (currentAPICount === 0) {
      this.ref?.close();
      this.ref = null;
    }
  }
}
