import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CheckMaintenanceModeInterceptor as BaseInterceptor } from '@cuepid/core';
import { AdministrationService } from '../services/administration.service';

@Injectable({
  providedIn: 'root',
})
export class CheckMaintenanceModeInterceptor extends BaseInterceptor {
  constructor(administrationService: AdministrationService, router: Router) {
    super(administrationService, router);
  }

  resolveMaintenancePagePath(): string[] {
    return ['/', 'maintenance'];
  }
}
