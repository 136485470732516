import { HttpBackend, HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  AdministrationService as BaseService,
  API_BASE_URL,
  Entry,
  InfluencerOffer,
  MaintenanceCondition,
  MaintenanceOption,
  MAINTENANCE_OPTION,
} from '@cuepid/core';
import { COOKIE_SERVICE } from '@cuepid/enterprise/core/injection-tokens';
import { CookiesStatic } from 'js-cookie';
import { Observable } from 'rxjs';
import { LoadIndicatorService } from './load-indicator.service';

@Injectable({
  providedIn: 'root',
})
export class AdministrationService extends BaseService {
  private readonly PROXY_HASH_COOKIE_NAME = 'CUEPID_ADMINISTRATOR_PROXY_HASH';

  constructor(
    backend: HttpBackend,
    private readonly http: HttpClient,
    @Inject(MAINTENANCE_OPTION) maintenanceOption: MaintenanceOption,
    @Inject(API_BASE_URL) private readonly apiBaseUrl: string,
    @Inject(COOKIE_SERVICE) private readonly cookieService: CookiesStatic,
    private loadIndicatorService: LoadIndicatorService
  ) {
    super(backend, maintenanceOption);
  }

  getMaintenanceStatus(): Observable<MaintenanceCondition | undefined> {
    const maintenanceCheckCount =
      this.loadIndicatorService.getMaintenanceCheckCount();
    this.loadIndicatorService.setMaintenanceCheckCount(
      maintenanceCheckCount + 1
    );
    return super.getMaintenanceStatus();
  }

  statusCheckRespFunc(): void {
    const currentCount = this.loadIndicatorService.getActiveConnections().value;
    if (currentCount > 0) {
      this.loadIndicatorService
        .getActiveConnections()
        .next(this.loadIndicatorService.getActiveConnections().value - 1);
    }

    const maintenanceCheckCount =
      this.loadIndicatorService.getMaintenanceCheckCount();
    if (maintenanceCheckCount > 0) {
      this.loadIndicatorService.setMaintenanceCheckCount(
        maintenanceCheckCount - 1
      );
    }
  }

  isInProxyLogin(): boolean {
    return !!this.cookieService.get(this.PROXY_HASH_COOKIE_NAME);
  }

  changeEntryStatus(entry: Entry, status: number): Observable<Entry> {
    return this.http.put<Entry>(
      `${this.apiBaseUrl}/administration/entry/${entry.id}/status`,
      {
        status,
      }
    );
  }

  changeOfferStatus(offer: InfluencerOffer, status: number): Observable<Entry> {
    return this.http.put<Entry>(
      `${this.apiBaseUrl}/administration/offer/${offer.pivot.offer_id}/influencer/${offer.id}/status`,
      {
        status,
      }
    );
  }
}
